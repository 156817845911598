.App {
  text-align: center;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar{
  width: 3px;
}

::-webkit-scrollbar-track{
  background-color: white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb{
  background-color: rgb(163, 163, 163);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
